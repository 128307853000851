@font-face {
  font-family: "ARCO";
  src: url("assets/fonts/CenturyGothic.ttf");
}
@font-face {
  font-family: "ARCO";
  src: url("assets/fonts/CabinetGrotesk.otf");
}
// Rest css
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: "Century Gothic", sans-serif;
  font-size: 16px;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}
// Style
.App {
  position: relative;
  background: #12131e;
  overflow: hidden;
  .bg-img {
    position: absolute;
  }
  .container {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    .page-header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;
      gap: 107px;
      @media only screen and (max-width: 868px) {
        gap: 50px;
      }
      @media only screen and (max-width: 768px) {
        gap: 30px;
      }
      @media only screen and (max-width: 668px) {
        flex-direction: column;
        gap: 20px;
        padding-top: 12px;
      }
      .logo-app {
        width: 124px;
        flex-shrink: 0;
      }
      .nav-menu {
        ul {
          display: flex;
          align-items: center;
          gap: 60px;
          @media only screen and (max-width: 1024px) {
            gap: 50px;
          }
          @media only screen and (max-width: 768px) {
            gap: 30px;
          }
          @media only screen and (max-width: 668px) {
            // flex-direction: column;
            gap: 20px;
          }
          li {
            color: #ececec;
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
          }
        }
      }
    }
    .page-main {
      .section-intro {
        padding: 116px 0 168px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (max-width: 668px) {
          padding: 60px 0 80px;
        }
        @media only screen and (max-width: 480px) {
          padding: 32px 0;
        }
        .title {
          color: #ececec;
          text-align: center;
          font-family: Cabinet Grotesk;
          font-size: 64px;
          font-weight: 700;
          line-height: 80px;
          max-width: 945px;
          margin: 0 24px;
          @media only screen and (max-width: 668px) {
            font-size: 48px;
            line-height: normal;
          }
          @media only screen and (max-width: 480px) {
            font-size: 32px;
          }
          span {
            color: #ff536b;
            font-family: Cabinet Grotesk;
            font-style: normal;
            font-weight: 700;
          }
        }
        .des {
          margin: 0 24px;
          max-width: 791px;
          color: rgba(236, 236, 236, 0.8);
          text-align: center;
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: 49px;
          padding-bottom: 32px;
          @media only screen and (max-width: 668px) {
            padding-bottom: 24px;
            font-size: 28px;
            line-height: normal;
          }
          @media only screen and (max-width: 480px) {
            font-size: 24px;
          }
        }
      }
      .section-available {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 42px;
        .title {
          color: #ececec;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
        .list-available {
          overflow: hidden;
          width: 100%;
          .item-slide {
            padding: 0 80px;
            @media only screen and (max-width: 1024px) {
              padding: 0 60px;
            }
            @media only screen and (max-width: 480px) {
              padding: 0 20px;
            }
          }
        }
      }
      .section-about {
        padding-top: 282px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (max-width: 668px) {
          padding-top: 100px;
        }
        @media only screen and (max-width: 480px) {
          padding-top: 40px;
        }
        .title {
          max-width: 888px;
          color: #ececec;
          text-align: center;
          font-family: Cabinet Grotesk;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 64px;
          margin: 0 24px;
          @media only screen and (max-width: 668px) {
            font-size: 32px;
            line-height: normal;
          }
          span {
            color: #ff536b;
            font-family: Cabinet Grotesk;
            font-style: normal;
            font-weight: 700;
          }
        }
        .des {
          margin: 0 24px;
          max-width: 840px;
          color: #ececec;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @media only screen and (max-width: 668px) {
            font-size: 18px;
            line-height: normal;
          }
        }
        .list-card {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 24px;
          padding: 120px 0 80px;
          margin: 0 24px;
          @media only screen and (max-width: 986px) {
            flex-wrap: wrap;
          }
          @media only screen and (max-width: 906px) {
            padding: 80px 0;
          }
          @media only screen and (max-width: 668px) {
            padding: 40px 0;
          }
          .item-card {
            max-width: 360px;
            border-radius: 24px;
            border: 1.5px solid #5581ff;
            background: radial-gradient(
                145.31% 96.49% at 50% 77.59%,
                rgba(72, 96, 203, 0.8) 0%,
                rgba(255, 255, 255, 0) 100%
              ),
              linear-gradient(
                0deg,
                rgba(72, 96, 203, 0.45) 0%,
                rgba(72, 96, 203, 0.45) 100%
              ),
              rgba(210, 210, 210, 0.2);
            background-blend-mode: soft-light, multiply, luminosity;
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.35);
            backdrop-filter: blur(20px);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 24px;
            .card-img {
              width: 144px;
            }
            .card-title {
              color: #ececec;
              text-align: center;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
            }
            .card-des {
              padding-top: 16px;
              color: rgba(236, 236, 236, 0.8);
              text-align: center;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }
      .section-how-it-work {
        padding: 80px 0;
        @media only screen and (max-width: 668px) {
          padding: 40px 0;
        }
        @media only screen and (max-width: 480px) {
          padding: 24px 0;
        }
        .top {
          margin: 0 auto;
        }
        .title {
          color: #ececec;
          text-align: center;
          font-family: Cabinet Grotesk;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 64px;
          margin: 0 24px;
          @media only screen and (max-width: 668px) {
            font-size: 32px;
            line-height: normal;
          }
        }
        .des {
          margin: 10px auto;
          max-width: 840px;
          color: #ececec;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 0 24px;
          @media only screen and (max-width: 668px) {
            font-size: 18px;
            line-height: normal;
          }
        }
        .list-card {
          display: flex;
          flex-direction: column;
          gap: 100px;
          align-items: center;
          padding-top: 100px;
          margin: 0 24px;
          @media only screen and (max-width: 768px) {
            gap: 50px;
          }
          @media only screen and (max-width: 668px) {
            padding-top: 40px;
          }
          @media only screen and (max-width: 632px) {
            gap: 32px;
          }
          .card-item {
            display: flex;
            align-items: center;
            gap: 120px;
            @media only screen and (max-width: 768px) {
              gap: 60px;
            }
            @media only screen and (max-width: 632px) {
              gap: 32px;
              flex-direction: column;
              &:nth-child(2) {
                flex-direction: column-reverse;
              }
            }
            .card-img {
              width: 355px;
              border-radius: 24px;
              border: 1.5px solid rgba(255, 255, 255, 0.19);
              background: radial-gradient(
                  145.31% 96.49% at 50% 77.59%,
                  rgba(72, 96, 203, 0.8) 0%,
                  rgba(255, 255, 255, 0) 100%
                ),
                linear-gradient(
                  0deg,
                  rgba(72, 96, 203, 0.45) 0%,
                  rgba(72, 96, 203, 0.45) 100%
                ),
                rgba(210, 210, 210, 0.2);
              background-blend-mode: soft-light, multiply, luminosity;
              box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.35);
              backdrop-filter: blur(100px);
              @media only screen and (max-width: 632px) {
                width: auto;
              }
            }
            .content {
              max-width: 642px;
              .title {
                color: #ececec;
                text-align: center;
                font-family: Cabinet Grotesk;
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 49px;
                @media only screen and (max-width: 768px) {
                  font-size: 28px;
                  line-height: normal;
                }
              }
              .des {
                padding-top: 16px;
              }
            }
          }
        }
      }
      .section-join-us {
        padding: 80px 0 30px;
        display: flex;
        background: linear-gradient(180deg, #12131e 0%, #1e1414 100%);
        justify-content: center;
        @media only screen and (max-width: 668px) {
          padding: 60px 0 30px;
        }
        @media only screen and (max-width: 480px) {
          padding: 30px 0;
        }
        .content {
          @media only screen and (max-width: 668px) {
            padding: 40px;
          }
          @media only screen and (max-width: 480px) {
            padding: 24px;
          }
          margin: 0 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 80px;
          border-radius: 24px;
          border: 1.5px solid rgba(85, 129, 255, 0.25);
          background: radial-gradient(
              62.23% 62.23% at 60.43% 73.72%,
              rgba(255, 254, 254, 0.25) 0%,
              rgba(255, 255, 255, 0) 100%
            ),
            linear-gradient(
              0deg,
              rgba(213, 213, 213, 0.3) 0%,
              rgba(213, 213, 213, 0.3) 100%
            ),
            rgba(76, 56, 198, 0.2);
          background-blend-mode: overlay, multiply, luminosity;
          backdrop-filter: blur(40px);
          max-width: fit-content;
          .title {
            color: #ececec;
            text-align: center;
            font-family: Cabinet Grotesk;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 64px;
            @media only screen and (max-width: 668px) {
              font-size: 32px;
              line-height: normal;
            }
          }
          .des {
            padding-top: 16px;
            color: #ececec;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media only screen and (max-width: 668px) {
              font-size: 18px;
              line-height: normal;
            }
          }
          .socials {
            @media only screen and (max-width: 668px) {
              padding-top: 34px;
              gap: 32px;
            }
            padding-top: 68px;
            display: flex;
            align-items: center;
            gap: 55px;
          }
        }
      }
    }
    .page-footer {
      padding: 63px 0 25px;
      display: flex;
      background-color: #1e1414;
      flex-direction: column;
      align-items: center;
      @media only screen and (max-width: 668px) {
        padding: 30px 0 25px;
      }
      .logo-footer {
        width: 134px;
      }
      .nav-menu {
        padding: 16px 0 44px;
        @media only screen and (max-width: 480px) {
          padding: 16px 0 32px;
        }
        ul {
          display: flex;
          align-items: center;
          gap: 50px;
          @media only screen and (max-width: 480px) {
            gap: 24px;
          }
          li {
            color: #fff;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 120% */
          }
        }
      }
      .copyright {
        padding-top: 19px;
        border-top: 1px solid #afcafe80;
        width: 80%;
        display: flex;
        justify-content: center;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .btn-primary {
      padding: 16px 24px;
      border-radius: 100px;
      background: linear-gradient(
        273deg,
        #ff536b 4.74%,
        rgba(255, 83, 107, 0.58) 103.12%
      );
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
      color: #ececec;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
    }
  }
}
